import React, { useState, useEffect } from 'react';
import '../App.css';
import { useToastContext } from '../Components/Toast.js'; 
import DefaultAvatar from '../Images/default avatar.svg';
import GoldBadge from '../Images/GoldBadge.svg';
import SilverBadge from '../Images/SilverBadge.svg';
import AquaBadge from '../Images/AquaBadge.svg';
import PurpleBadge from '../Images/PurpleBadge.svg';
import PurplehazeBadge from '../Images/PurplehazeBadge.svg';
import TealBadge from '../Images/TealBadge.svg';
import BrownBadge from '../Images/BrownBadge.svg';
import BlueBadge from '../Images/BlueBadge.svg';
import SunfireBadge from '../Images/SunfireBadge.svg';
import Warning from '../Images/Warning.svg';
import GoldCircle from '../Images/GoldCircle.svg';
import AquaCircle from '../Images/AquaCircle.svg';
import SilverCircle from '../Images/SilverCircle.svg';
import PurpleCircle from '../Images/PurpleCircle.svg';
import PurpleHazeCircle from '../Images/PurpleHazeCircle.svg';
import TealCircle from '../Images/TealCircle.svg';
import BrownCircle from '../Images/BrownCircle.svg';
import BlueCircle from '../Images/BlueCircle.svg';
import SunfireCircle from '../Images/SunfireCircle.svg';
import CancelButton from '../Images/CancelButton.svg';
import ApplyChanges from '../Images/ApplyChanges.svg';
import CloseIcon from '../Images/CloseIcon.svg';
import ShoutoutButton from '../Images/ShoutoutButton.svg';

const styleMapping = {
    GOLD: GoldCircle,
    SILVER: SilverCircle,
    AQUA: AquaCircle,
    PURPLE: PurpleCircle,
    PURPLEHAZE: PurpleHazeCircle,
    TEAL: TealCircle,
    BROWN: BrownCircle,
    BLUE: BlueCircle,
    SUNFIRE: SunfireCircle,
  };

const badgeMapping = {
    GOLD: GoldBadge,
    SILVER: SilverBadge,
    AQUA: AquaBadge,
    PURPLE: PurpleBadge,
    PURPLEHAZE: PurplehazeBadge,
    TEAL: TealBadge,
    BROWN: BrownBadge,
    BLUE: BlueBadge,
    SUNFIRE: SunfireBadge,
  };

const CustomCheckbox = ({ checked, onChange }) => (
  <label className="custom-checkbox">
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span className="checkmark"></span>
  </label>
);

const formatToReadableDateET = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp * 1000);
    return new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  const formatToDateInputET = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp * 1000);
    return new Intl.DateTimeFormat('en-CA', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
      .format(date)
      .replace(/\//g, '-'); // Convert to YYYY-MM-DD
  };

const Legends = () => {
  const [legends, setLegends] = useState([]); // Ensure it's initialized as an array
  const [avatars, setAvatars] = useState({});
  const [loading, setLoading] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedLegend, setSelectedLegend] = useState(null); // Track selected legend for the popup
  const [currentShoutout, setCurrentShoutout] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [editedShoutout, setEditedShoutout] = useState('');
  const [sortedLegends, setSortedLegends] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState('GOLD');
  const [totalRevenueUSD, setTotalRevenueUSD] = useState(0);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  
  const [showPopup, setShowPopup] = useState(false);

  const { sendSuccess, sendWarning } = useToastContext();

  const [popupCheckboxState, setPopupCheckboxState] = useState({
    badge: false,
    ring: false,
    leaderboard: false,
  });

  const handleCheckboxChange = (pubkey, field) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [pubkey]: {
        ...prevState[pubkey],
        [field]: !prevState[pubkey][field],
      },
    }));
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && showPopup) {
        setShowPopup(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [showPopup]);

  const isWithinLast24Hours = (dateString) => {
    const legendDate = new Date(dateString);
    const currentDate = new Date();
    return (currentDate - legendDate) / (1000 * 60 * 60) <= 24;
  };

  const formatDonatedBTC = (btc) => {
    if (btc === "0" || parseFloat(btc) === 0) {
      return "GIFT";
    }
    const sats = Math.floor(parseFloat(btc) * 1e8); // Convert BTC to sats (1 BTC = 100,000,000 sats)
    return new Intl.NumberFormat().format(sats); // Format with commas
  };

  const fetchAvatars = async (pubKeys) => {
    if (!pubKeys.length) return;
    const payload = JSON.stringify(["user_infos", { pubkeys: pubKeys }]);
    try {
      const response = await fetch("https://dev.primal.net/api", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: payload,
      });

      if (response.ok) {
        const data = await response.json();
        const avatarsData = {};
        if (Array.isArray(data)) {
          data.forEach((user) => {
            try {
              const userData = JSON.parse(user.content);
              avatarsData[user.pubkey] = userData.picture || DefaultAvatar;
            } catch (err) {
              console.error("Error parsing user content:", err);
            }
          });
        }
        setAvatars(avatarsData);
      } else {
        console.error("Error fetching avatars:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching avatars:", error);
    }
  };

  const fetchLegends = async () => {
    setLoading(true);
    try {
      const signedEvent = await window.nostr.signEvent({
        created_at: Math.floor(Date.now() / 1000),
        kind: 10000222,
        tags: [],
        content: 'fetching legends',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: signedEvent,
          request: ['get_legends', {}],
        }),
      });

      const data = await response.json();
      if (data?.legends && Array.isArray(data.legends)) {
        setLegends(data.legends);
        setSortedLegends(data.legends);

        const initialCheckboxStates = {};
        data.legends.forEach((legend) => {
          initialCheckboxStates[legend.pubkey] = {
            badge: legend.custom_badge || false,
            ring: legend.avatar_glow || false,
            leaderboard: legend.in_leaderboard || false,

          };
          const totalRevenue = parseFloat(data.total_revenue_usd || 0);
        setTotalRevenueUSD(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(totalRevenue));
        });

        setCheckboxStates(initialCheckboxStates);

        const pubKeys = [...new Set(data.legends.map((legend) => legend.pubkey))];
        fetchAvatars(pubKeys);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching legends:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLegends();
  }, []);

  useEffect(() => {
    const sortLegends = () => {
      // Step 1: Remove duplicates by pubkey
      const uniqueLegends = Array.from(new Map(legends.map(legend => [legend.pubkey, legend])).values());
  
      let sorted = [...uniqueLegends];
  
      // Step 2: Sort based on the selected key (if a key is selected)
      if (sortConfig.key === 'sats') {
        sorted.sort((a, b) => {
          const satsA = parseFloat(a.donated_btc) || 0;
          const satsB = parseFloat(b.donated_btc) || 0;
  
          // Ensure GIFT users are always at the bottom
          if (satsA === 0 && satsB === 0) return 0;
          if (satsA === 0) return 1;
          if (satsB === 0) return -1;
  
          return sortConfig.direction === 'asc' ? satsA - satsB : satsB - satsA;
        });
      } else if (sortConfig.key === 'since') {
        sorted.sort((a, b) => {
          const dateA = new Date(a.legend_since);
          const dateB = new Date(b.legend_since);
          return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        });
      }
  
      // Step 3: On initial load (no sortConfig.key), move warnings to the top
      if (!sortConfig.key) {
        const legendsWithWarnings = sorted.filter((legend) => legend.edited_shoutout && legend.edited_shoutout.trim() !== '');
        const legendsWithoutWarnings = sorted.filter((legend) => !legend.edited_shoutout || legend.edited_shoutout.trim() === '');
        sorted = [...legendsWithWarnings, ...legendsWithoutWarnings];
      }
  
      setSortedLegends(sorted);
    };
  
    sortLegends(); // Run sorting whenever legends or sortConfig changes
  }, [legends, sortConfig]);
  
  
  // Toggle sorting on header click
  const toggleSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { key, direction: 'asc' };
    });
  };
  

  const handleEditClick = (legend) => {
    setSelectedLegend(legend);
    setCurrentShoutout(legend.current_shoutout || '');
    setEditedShoutout(legend.edited_shoutout || '');
    setSelectedDate(legend.legend_since ? formatToDateInputET(legend.legend_since) : formatToDateInputET(Date.now() / 1000));
    setSelectedStyle(legend.style || 'GOLD');

    // Initialize checkbox states
    setPopupCheckboxState({
      badge: legend.custom_badge || false,
      ring: legend.avatar_glow || false,
      leaderboard: legend.in_leaderboard || false,
    });

    setShowPopup(true);
  };

  const handlePopupCheckboxChange = (field) => {
    setPopupCheckboxState((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleStyleChange = (style) => {
    setSelectedStyle(style);
  };

  const handleApplyChanges = async () => {
    if (!selectedLegend) return;
  
    try {
      const selectedDateET = new Date(`${selectedDate}T00:00:00-05:00`);
      const timestamp = Math.floor(selectedDateET.getTime() / 1000);
  
      const payload = {
        request: ['update_legends', {
          pubkey: selectedLegend.pubkey,
          style: selectedStyle, // Updated style
          custom_badge: popupCheckboxState.badge, // Custom badge checkbox state
          avatar_glow: popupCheckboxState.ring, // Ring glow checkbox state
          in_leaderboard: popupCheckboxState.leaderboard, // Leaderboard checkbox state
          current_shoutout: editedShoutout, // Set current shoutout to the edited one
          edited_shoutout: "", // Set edited shoutout to an empty string
          legend_since: timestamp, // Apply the selected date
        }],
      };
  
      const signedEvent = await window.nostr.signEvent({
        created_at: Math.floor(Date.now() / 1000),
        kind: 10000222,
        tags: [],
        content: JSON.stringify(payload),
      });
  
      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: signedEvent,
          request: ['update_legend', payload.request[1]],
        }),
      });
  
      if (response.ok) {
        sendSuccess('Shoutout and settings updated successfully');
        
        // Update local state: Set current shoutout and reset edited shoutout
        setLegends((prevLegends) => 
          prevLegends.map((legend) =>
            legend.pubkey === selectedLegend.pubkey
              ? { ...legend, current_shoutout: editedShoutout, edited_shoutout: "" }
              : legend
          )
        );
  
        fetchLegends(); // Optionally refetch legends to ensure consistency
      } else {
        sendWarning('Failed to update legend');
        console.error('Update failed with response:', await response.text());
      }
    } catch (error) {
      sendWarning('Error updating legend');
      console.error('Error during update:', error);
    } finally {
      setShowPopup(false);
    }
  };
  

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };
  
  const totalLegends = legends.length;
  const paidLegends = legends.filter((legend) => parseFloat(legend.donated_btc) > 0).length;
  const legends24hrs = legends.filter((legend) => isWithinLast24Hours(legend.legend_since)).length;

  return (
    <div className="MainBody">
         {loading && <div className="loader"><span></span></div>}

{!loading && (
    <>
      <div className="UMTitle">Legends</div>
      <div className="PremiumCardZone">
        <div className="PremiumCardsContainer">
          <div className="PremiumCard">
            <div className="PremiumCardTitle">Total Legends</div>
            <div className="PremiumCardValue">{totalLegends}</div>
          </div>
          <div className="PremiumCard">
            <div className="PremiumCardTitle">Paid Legends</div>
            <div className="PremiumCardValue">{paidLegends}</div>
          </div>
          <div className="PremiumCard">
            <div className="PremiumCardTitle">New Legends 24h</div>
            <div className="PremiumCardValue">{legends24hrs}</div>
          </div>
          <div className="PremiumCard">
            <div className="PremiumCardTitle">Total Legend Rev.</div>
            <div className="PremiumCardValue">{totalRevenueUSD}</div>
          </div>
        </div>
      </div>

      <div className="LLHeadersLegends">
        <div className="LLHeader1">USER</div>
        <div
              className="LLHeader2 clickable"
              onClick={() => toggleSort('sats')}
            >
              SATS {sortConfig.key === 'sats' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
        </div>
        <div
              className="LLHeader3 clickable"
              onClick={() => toggleSort('since')}
            >
              SINCE {sortConfig.key === 'since' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
        </div>
        <div className="LLHeader4">BADGE</div>
        <div className="LLHeader5">RING</div>
        <div className="LLHeader6">LB</div>
        <div className="LLHeader7">THEME</div>
      </div>


      <div className="SubscriptionList">
      {sortedLegends.map((legend) => {
        const badgeImage = badgeMapping[legend.style] || GoldBadge;
        const showWarning = legend.edited_shoutout && legend.edited_shoutout.trim() !== '';
        return (
          <div key={legend.pubkey} className="SubscriptionRowLegends">
            <div className="Avatar">
              <a href={`https://primal.net/p/${legend.pubkey}`} target="_blank" rel="noopener noreferrer">
                <img src={avatars[legend.pubkey] || DefaultAvatar} alt="Avatar" className="avatar-img" />
              </a>
            </div>
            <div className="RowItem NameRowItem">
              <a href={`https://primal.net/p/${legend.pubkey}`} target="_blank" rel="noopener noreferrer" className="NameLink">
                {legend.name || 'Unknown User'}
              </a>
            </div>
            <div className="RowItem DonatedSats">
              {formatDonatedBTC(legend.donated_btc)}
            </div>
           <div className="RowItem">{formatToReadableDateET(legend.legend_since)}</div>

            <div className="RowItem BadgeBox">
          <CustomCheckbox
                    checked={checkboxStates[legend.pubkey]?.badge || false}
                    onChange={() => {}}
                    disabled={true}
                />
            </div>
            <div className="RowItem RingBox">
          <CustomCheckbox
            checked={checkboxStates[legend.pubkey]?.ring || false}
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div className="RowItem LeaderBoardBox">
          <CustomCheckbox
            checked={checkboxStates[legend.pubkey]?.leaderboard || false}
            onChange={() => {}}
            disabled={true}
          />
          </div>
            <div className="RowItem LegendBadge">
              <img src={badgeImage} className="LegendBadge" alt="Badge" />
            </div>
            <div className="RowItem">
                {(!legend.current_shoutout || legend.current_shoutout.trim() === '') ? (
                    <img src={ShoutoutButton} className="ShoutoutButton" alt="Shout out button" />
                ) : (
                    <div className="PlaceholderSpace" />
                )}
            </div>
            <div className="RowItem">
              {showWarning &&<img src={Warning} className="Warning" alt="Warning" />}
            </div>
            <div className="RowItem EditRowItem" onClick={() => handleEditClick(legend)}>EDIT</div>
          </div>
        )
})}
      </div>

      {showPopup && (
        <div className="PopupOverlay">
          <div className="PopupContent">
            <div className="PopupTitle">Edit Legend Profile
            <img
                src={CloseIcon}
                alt="Close"
                className="CloseButton"
                onClick={() => setShowPopup(false)}
              />
            </div>
            <div className="PopupSections">
            <div className="PopupSection">
              <label className="PopupLabel">Current Shoutout:</label>
              <textarea value={currentShoutout} onChange={(e) => setCurrentShoutout(e.target.value)} />
            </div>
            <div className="PopupSection">
              <label className="PopupLabel">Edited Shoutout:</label>
              <textarea value={editedShoutout} onChange={(e) => setEditedShoutout(e.target.value)} />

            </div>
            </div>
            <div className="PopupOptions">
              <div className="PopupCheckboxes">
              <div className="BadgePopup">BADGE:
                <CustomCheckbox
                  checked={popupCheckboxState.badge}
                  onChange={() => handlePopupCheckboxChange('badge')}
                />
              </div>
              <div className="BadgePopup">RING:
                <CustomCheckbox
                  checked={popupCheckboxState.ring}
                  onChange={() => handlePopupCheckboxChange('ring')}
                />
              </div>
              </div>
              <div className="LeaderboardPopup">LEADERBOARD:
                <CustomCheckbox
                  checked={popupCheckboxState.leaderboard}
                  onChange={() => handlePopupCheckboxChange('leaderboard')}
                />
              </div>
              <div className="ThemeSelection">
  <span>Select Style:</span>
  <div className="ThemeColors">
    {Object.keys(styleMapping).slice(0, 5).map((style) => (
      <div
        key={style}
        className={`ColorCircle ${selectedStyle === style ? 'selected-style' : ''}`}
        onClick={() => handleStyleChange(style)}
        style={{
          border: selectedStyle === style ? '2px solid red' : 'none',
        }}
      >
        <img src={styleMapping[style]} alt={style} />
      </div>
    ))}
  </div>
  
  <div className="ThemeColors1">
    {Object.keys(styleMapping).slice(5).map((style) => (
      <div
        key={style}
        className={`ColorCircle ${selectedStyle === style ? 'selected-style' : ''}`}
        onClick={() => handleStyleChange(style)}
        style={{
          border: selectedStyle === style ? '2px solid red' : 'none',
        }}
      >
        <img src={styleMapping[style]} alt={style} />
      </div>
    ))}
  </div>
</div>
<div className="DatePickerSection">
        <label className="PopupLabel">Select Legend Since Date:</label>
         <input
            type="date"
            value={selectedDate}
            className="dateText"
            onChange={(e) => setSelectedDate(e.target.value)}
         />
</div>
            </div>
            <div className="PopupActions">
              <button onClick={() => setShowPopup(false)} className="PopupButton">
                <img src={CancelButton} alt=""></img>
              </button>
              <button onClick={handleApplyChanges} className="PopupButton">
                <img src={ApplyChanges} alt=""></img>
              </button>
            </div>
          </div>
        </div>
    )}
    </>
      )}
    </div>
  );
};

export default Legends;

import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import Home from './Components/Home';
import ActiveUsers from './Components/ActiveUsers';
import VerifiedUsers from './Components/VerifiedUsers';
import NetworkStats from './Components/NetworkStats';
import UserModeration from './Components/UserModeration';
import PrimalWallet from './Components/PrimalWallet';
import UserPage from './Components/UserPage';
import SystemStatus from './Components/SystemStatus';
import UploadedMedia from './Components/Uploaded_Media';
import Toaster from './Components/Toast';
import ProfileComponent from './Components/ProfileComponent';
import Admin from './Components/Admin';
import PromoCodes from './Components/PromoCodes';
import Premium from './Components/Premium';
import ReservedNames from './Components/ReservedNames';
import PromoBatch from './Components/PromoBatch';
import ChangeName from './Components/ChangeName';
import ActivateManually from './Components/ActivateManually';
import Home2 from './Components/Home2';
import ExpiredSubs from './Components/ExpiredSubs';
import Legends from './Components/Legends';

function App() {
  const [pubkey, setPubkey] = useState(null);
  const [firstAccessibleLink, setFirstAccessibleLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingPaths, setIsFetchingPaths] = useState(true);
  const [accessiblePaths, setAccessiblePaths] = useState([]);

  const fetch_admin = async (role, func, args) => {
    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'i want in',
      });

      console.log('event_from_user:', e);

      const requestPayload = {
        event_from_user: e,
        request: ['admin', { role, func, args }],
      };

      // Print request payload
      console.log('request:', requestPayload);

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['admin', { role, func, args }],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      return { error: error.message };
    }
  };

  useEffect(() => {
    setIsFetchingPaths(true);
    const fetchAccessiblePaths = async () => {
      if (pubkey) {
        const response = await fetch_admin('everyone', 'get_accessible_paths', { pubkey });
        if (!response.error && response.accessible_paths) {
          const accessiblePaths = response.accessible_paths;
          const firstLink = [
            '/home',
            '/home2',
            '/active_users',
            '/promo_codes',
            '/premium',
            '/verified_users',
            '/primal_wallet',
            '/user_moderation',
            '/uploaded_media',
            '/network_stats',
            '/monitoring',
            '/admin',
          ].find((link) => accessiblePaths.includes(link));
          setAccessiblePaths(accessiblePaths);
          setFirstAccessibleLink(firstLink);
        }
      }
      setIsFetchingPaths(false);
    };

    fetchAccessiblePaths().catch((error) => {
      console.error(error);
      setIsFetchingPaths(false);
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [pubkey]);


  const Content = () => {
    if (isLoading) {
      return <div className="MainBody"> <div className="loader"><span></span></div></div>;
    } else {

      return (
        <Switch>
          <Route exact path="/">
            {firstAccessibleLink ? <Redirect to={firstAccessibleLink} /> : null}
          </Route>
          <Route path="/home" exact component={Home} />
          <Route path="/home2" exact component={Home2} />
          <Route path="/active_users" component={ActiveUsers} />
          <Route path="/premium" exact component={Premium} />
          <Route path="/premium/reserved_names" component={ReservedNames} />
          <Route path="/premium/expired_subs" component={ExpiredSubs} />
          <Route path="/premium/promo_codes" component={PromoCodes} />
          <Route path="/premium/batch/:name" component={PromoBatch} />
          <Route path="/premium/change_name" component={ChangeName} />
          <Route path="/premium/legends" component={Legends} />
          <Route path="/verified_users" component={VerifiedUsers} />
          <Route path="/network_stats" component={NetworkStats} />
          <Route path="/primal_wallet" exact component={PrimalWallet} />
          <Route path="/primal_wallet/activate_manually" component={ActivateManually} />
          <Route path="/user/:pubkey" exact component={UserPage} />
          <Route path="/user_moderation" component={UserModeration} />
          <Route path="/uploaded_media" component={UploadedMedia} />
          <Route path="/monitoring" component={SystemStatus} />
          <Route path="/admin" component={Admin} />
        </Switch>
      );
    }
  };

  return (
    <Router>
      <div className="App">
        <Toaster>
          <div className="Container">
            <Sidebar pubkey={pubkey} accessiblePaths={accessiblePaths} />
              <ProfileComponent setPubkey={setPubkey} />
              <Content />
          </div>
        </Toaster>
      </div>
    </Router>
  );
}

export default App;
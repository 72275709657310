import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import '../App.css';
import ReservedNamesIcon from '../Images/ReservedNames.svg';
import PromoCodesIcon from '../Images/PromoCodes.svg';
import DefaultAvatar from '../Images/default avatar.svg';
import ChangeName from '../Images/ChangeName.svg';
import MonthlyRevenuesGraph from './MonthlyRevenuesGraph';
import refresh from '../Images/refreshButton.svg';
import expiredButton from '../Images/expiredButton.svg';
import LegendsButton from '../Images/LegendsButton.svg';

const PremiumSubs = () => {
    const history = useHistory();
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [monthlyRevenueAverage, setMonthlyRevenueAverage] = useState(0);
    const [previousMonthRevenue, setPreviousMonthRevenue] = useState(0);
    const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
    const [projectedMonthRevenue, setProjectedMonthRevenue] = useState(0);
    const [legendMonthlyAverage, setLegendMonthlyAverage] = useState(0);
    const [previousMonthLegendRevenue, setPreviousMonthLegendRevenue] = useState(0);
    const [currentMonthLegendRevenue, setCurrentMonthLegendRevenue] = useState(0);
    const [legendRevenueProjection, setLegendRevenueProjection] = useState(0);
    const [premiumMonthlyAverage, setPremiumMonthlyAverage] = useState(0);
    const [previousMonthPremiumRevenue, setPreviousMonthPremiumRevenue] = useState(0);
    const [currentMonthPremiumRevenue, setCurrentMonthPremiumRevenue] = useState(0);
    const [premiumRevenueProjection, setPremiumRevenueProjection] = useState(0);
    const [avatars, setAvatars] = useState({});
    const [avatarsLoading, setAvatarsLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const navigateToReservedNames = () => {
        history.push('/premium/reserved_names');
    };

    const navigateToPromoCodes = () => {
        history.push('/premium/promo_codes');
    };

    const navigateToChangeName = () => {
        history.push('/premium/change_name');
    };

    const navigateToExpiredSubs = () => {
        history.push('/premium/expired_subs');
    };

    const navigatetoLegends = () => {
        history.push('/premium/legends');
    }

    const calculateSubAnnualValue = (activeSubs) => activeSubs * 5.5 * 12;

    const calculateSubRevenueProjection = (subAnnualValue, newSubs24h) =>
        subAnnualValue + newSubs24h * 365 * 12 * 5.5;

    const npubEncode = (hex) => {
        const words = bech32.toWords(hexToBytes(hex));
        return bech32.encode('npub', words);
    };

    const fetchAvatars = async (pubKeys) => {
        const payload = JSON.stringify(["user_infos", { "pubkeys": pubKeys }]);
        try {
            const response = await fetch("https://dev.primal.net/api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            });
    
            if (response.ok) {
                const data = await response.json();
                const avatarsData = {};
                if (data && Array.isArray(data)) {
                    data.forEach(user => {
                        try {
                            const userData = JSON.parse(user.content); // Parse the 'content' field
                            avatarsData[user.pubkey] = userData.picture || DefaultAvatar; // Extract 'picture' or use default
                        } catch (err) {
                            console.error("Error parsing user content:", err);
                        }
                    });
                }
                setAvatars(avatarsData);
            } else {
                console.error("Error fetching avatars:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching avatars:", error);
        }
        setAvatarsLoading(false); // Set to false when avatars are fully loaded
    };

    const fetchSubscriptionData = async () => {
            try {
                const e = await window.nostr.signEvent({
                    created_at: Math.floor(new Date().getTime() / 1000),
                    kind: 10000222,
                    tags: [],
                    content: 'i want in',
                });
    
                const response = await fetch('https://admin.primal.net/api2', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        event_from_user: e,
                        request: ['subscriptions', { limit: 10000, offset: 0 }],
                    }),
                });
    
                const data = await response.json();
                if (data && !data.error) {
                    setSubscriptionData(data);
                    setTableData(data.subscriptions);
                    
                    const pubKeys = [...new Set(data.subscriptions.map(sub => sub.receiver_pubkey))];
                    fetchAvatars(pubKeys);// Display all subscription items
    
                    // Monthly data
                    const monthlyData = data.monthly_subscriptions_revenue_usd;
    
                    // Total days in the current month
                    const getTotalDaysInMonth = (year, month) => new Date(year, month, 0).getDate();
    
                    const currentDate = new Date();
                    const currentMonth = currentDate.getUTCMonth() + 1;
                    const currentYear = currentDate.getUTCFullYear();
                    const totalDaysInCurrentMonth = getTotalDaysInMonth(currentYear, currentMonth);
    
                    // Total Revenue Average
                    const allRevenues = monthlyData.regular.concat(monthlyData.legend).reduce((acc, curr) => acc + parseFloat(curr[1]), 0);
                    const numMonths = Math.max(monthlyData.regular.length, monthlyData.legend.length);
                    setMonthlyRevenueAverage(`$${Math.round(allRevenues / numMonths).toLocaleString('en-US')}`);
    
                    // Previous Month Revenue
                    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
                    const previousMonthRevenue = monthlyData.regular.concat(monthlyData.legend).filter(([timestamp]) => {
                        const month = new Date(parseInt(timestamp, 10) * 1000).getUTCMonth() + 1;
                        return month === previousMonth;
                    }).reduce((acc, [, revenue]) => acc + parseFloat(revenue), 0);
                    setPreviousMonthRevenue(`$${Math.round(previousMonthRevenue).toLocaleString('en-US')}`);
    
                    // Current Month Revenue
                    const currentMonthRevenue = monthlyData.regular.concat(monthlyData.legend).filter(([timestamp]) => {
                        const month = new Date(parseInt(timestamp, 10) * 1000).getUTCMonth() + 1;
                        return month === currentMonth;
                    }).reduce((acc, [, revenue]) => acc + parseFloat(revenue), 0);
                    setCurrentMonthRevenue(`$${Math.round(currentMonthRevenue).toLocaleString('en-US')}`);
    
                    // Projected Month Revenue
                    if (currentMonthRevenue > 0) {
                        const daysElapsed = currentDate.getUTCDate();
                        const projectedRevenue = (currentMonthRevenue / daysElapsed) * totalDaysInCurrentMonth;
                        setProjectedMonthRevenue(`$${Math.round(projectedRevenue).toLocaleString('en-US')}`);
                    } else {
                        setProjectedMonthRevenue('N/A');
                    }
    
                    // Legend-specific Revenue
                    const legendTotal = monthlyData.legend.reduce((acc, curr) => acc + parseFloat(curr[1]), 0);
                    setLegendMonthlyAverage(`$${Math.round(legendTotal / monthlyData.legend.length).toLocaleString('en-US')}`);
    
                    const previousMonthLegend = monthlyData.legend.filter(([timestamp]) => {
                        const month = new Date(parseInt(timestamp, 10) * 1000).getUTCMonth() + 1;
                        return month === previousMonth;
                    }).reduce((acc, [, revenue]) => acc + parseFloat(revenue), 0);
                    setPreviousMonthLegendRevenue(`$${Math.round(previousMonthLegend).toLocaleString('en-US')}`);
    
                    const currentMonthLegend = monthlyData.legend.filter(([timestamp]) => {
                        const month = new Date(parseInt(timestamp, 10) * 1000).getUTCMonth() + 1;
                        return month === currentMonth;
                    }).reduce((acc, [, revenue]) => acc + parseFloat(revenue), 0);
                    setCurrentMonthLegendRevenue(`$${Math.round(currentMonthLegend).toLocaleString('en-US')}`);
    
                    if (currentMonthLegend > 0) {
                        const projectedLegend = (currentMonthLegend / currentDate.getUTCDate()) * totalDaysInCurrentMonth;
                        setLegendRevenueProjection(`$${Math.round(projectedLegend).toLocaleString('en-US')}`);
                    } else {
                        setLegendRevenueProjection('N/A');
                    }
    
                    // Premium-specific Revenue
                    const premiumTotal = monthlyData.regular.reduce((acc, curr) => acc + parseFloat(curr[1]), 0);
                    setPremiumMonthlyAverage(`$${Math.round(premiumTotal / monthlyData.regular.length).toLocaleString('en-US')}`);
    
                    const previousMonthPremium = monthlyData.regular.filter(([timestamp]) => {
                        const month = new Date(parseInt(timestamp, 10) * 1000).getUTCMonth() + 1;
                        return month === previousMonth;
                    }).reduce((acc, [, revenue]) => acc + parseFloat(revenue), 0);
                    setPreviousMonthPremiumRevenue(`$${Math.round(previousMonthPremium).toLocaleString('en-US')}`);
    
                    const currentMonthPremium = monthlyData.regular.filter(([timestamp]) => {
                        const month = new Date(parseInt(timestamp, 10) * 1000).getUTCMonth() + 1;
                        return month === currentMonth;
                    }).reduce((acc, [, revenue]) => acc + parseFloat(revenue), 0);
                    setCurrentMonthPremiumRevenue(`$${Math.round(currentMonthPremium).toLocaleString('en-US')}`);
    
                    if (currentMonthPremium > 0) {
                        const projectedPremium = (currentMonthPremium / currentDate.getUTCDate()) * totalDaysInCurrentMonth;
                        setPremiumRevenueProjection(`$${Math.round(projectedPremium).toLocaleString('en-US')}`);
                    } else {
                        setPremiumRevenueProjection('N/A');
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching API data:', error);
                setLoading(false);
            }
        };
    
        useEffect(() => {
            fetchSubscriptionData();
        }, []);
        
        

    // Helper function to format timestamp
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    // Helper function to get SKU label based on months
    const getSkuLabel = (months) => {
        switch (months) {
            case 1:
                return "1M";
            case 3:
                return "3M";
            case 12:
                return "12M";
            case -1:
                return "LGD";
            default:
                return months;
        }
    };

    const refreshContent = async () => {
        // Reset all state variables
        setSubscriptionData(null);
        setTableData([]);
        setMonthlyRevenueAverage(0);
        setPreviousMonthRevenue(0);
        setCurrentMonthRevenue(0);
        setProjectedMonthRevenue(0);
        setLegendMonthlyAverage(0);
        setPreviousMonthLegendRevenue(0);
        setCurrentMonthLegendRevenue(0);
        setLegendRevenueProjection(0);
        setPremiumMonthlyAverage(0);
        setPreviousMonthPremiumRevenue(0);
        setCurrentMonthPremiumRevenue(0);
        setPremiumRevenueProjection(0);
        setAvatars({});
        setAvatarsLoading(true);
        setLoading(true);
    
        // Fetch the subscription data again
        await fetchSubscriptionData();
    };

    const renderValue = (value) => `$${Math.round(value).toLocaleString('en-US')}`;

    return (
        <>
            <div className="MainBody">
                <div className="PremiumHeader">
                    <div className="UMTitle">subscriptions</div>
                    <div className="PremiumPageButtons">
                        <button className="RefreshButtons" onClick={refreshContent}>
                            <img src={refresh} alt="RefreshButton"/>
                        </button>
                        <button className="LegendsButton" onClick={navigatetoLegends}>
                            <img src={LegendsButton} alt="LegendsButton"/>
                        </button>
                        <button className="ExpiredButton" onClick={navigateToExpiredSubs}>
                            <img src={expiredButton} alt="ExpiredButton"/>
                        </button>
                        <button className="ChangeNameButton" onClick={navigateToChangeName}>
                            <img src={ChangeName} alt="Change Name Button" />
                        </button>
                        <button className="ReservedNamesButton" onClick={navigateToReservedNames}>
                            <img src={ReservedNamesIcon} alt="Reserved Names" />
                        </button>
                        <button className="PromoCodesButton" onClick={navigateToPromoCodes}>
                            <img src={PromoCodesIcon} alt="Promo Codes" />
                        </button>
                    </div>
                </div>

                <div className="PremiumCardZone">
                    <div className="PremiumCardsContainer">
                        <div className="PremiumCard">
                            <div className="PremiumCardTitle">Active Subs</div>
                            <div className="PremiumCardValue">{subscriptionData?.active_subscriptions ?? 0}</div>
                        </div>
                        <div className="PremiumCard">
                            <div className="PremiumCardTitle">Legends</div>
                            <div className="PremiumCardValue">{subscriptionData?.active_legend_subscriptions ?? 0}</div>
                        </div>
                        <div className="PremiumCard">
                            <div className="PremiumCardTitle">New Subs 24h</div>
                            <div className="PremiumCardValue">{subscriptionData?.new_subscriptions_24h ?? 0}</div>
                        </div>
                        <div className="PremiumCard">
                            <div className="PremiumCardTitle">Revenue</div>
                            <div className="PremiumCardValue">{renderValue(subscriptionData?.subscriptions_revenue_usd ?? 0)}</div>
                        </div>
                        <div className="PremiumCard">
                            <div className="PremiumCardTitle">Sub Annual Value</div>
                            <div className="PremiumCardValue">{renderValue(subscriptionData?.active_subscriptions ? calculateSubAnnualValue(subscriptionData.active_subscriptions) : 0)}</div>
                        </div>
                    </div>
                </div>

                <div className="MonthlyRevenueZone">
                    <div className="MonthlyRevenues">
                        <div className="MonthlyRevenuesTitle">Monthly Revenues</div>
                        <div className="MonthlyRevenueGraph"><MonthlyRevenuesGraph /></div>
                    </div>
                    <div className="MontlyRevenueTitles">
                        <div className="MonthlyRevenueTitle">Avg. Monthly Revenue</div>
                        <div className="MonthlyRevenueTitle">Previous Month Revenue</div>
                        <div className="MonthlyRevenueTitle">Current Month Revenue</div>
                        <div className="MonthlyRevenueTitle">Current Month Revenue Projection</div>
                    </div>
                    <div className="TotalMonthlyRevenueList">
                        <div className="TotalMonthlyRevenue">{monthlyRevenueAverage}</div>
                        <div className="TotalMonthlyRevenue">{previousMonthRevenue}</div>
                        <div className="TotalMonthlyRevenue">{currentMonthRevenue}</div>
                        <div className="TotalMonthlyRevenue">{projectedMonthRevenue}</div>
                    </div>
                    <div className="LegendMonthlyRevenueList">
                        <div className="LegendMonthlyRevenue">{legendMonthlyAverage}</div>
                        <div className="LegendMonthlyRevenue">{previousMonthLegendRevenue}</div>
                        <div className="LegendMonthlyRevenue">{currentMonthLegendRevenue}</div>
                        <div className="LegendMonthlyRevenue">{legendRevenueProjection}</div>
                    </div>
                    <div className="PremiumMonthlyRevenueList">
                        <div className="PremiumMonthlyRevenue">{premiumMonthlyAverage}</div>
                        <div className="PremiumMonthlyRevenue">{previousMonthPremiumRevenue}</div>
                        <div className="PremiumMonthlyRevenue">{currentMonthPremiumRevenue}</div>
                        <div className="PremiumMonthlyRevenue">{premiumRevenueProjection}</div>
                    </div>
                </div>


                <div className="SubscriptionList">
    {tableData.map((item, index) => {
        const isLGD = item.months === -1; // Check if SKU is "LGD"
        const rowClass = isLGD ? "lgd-row" : ""; // Apply special class for "LGD"
        const skuLabel = getSkuLabel(item.months); // Get SKU label based on months
        const userName = item.name || 'Unknown'; // Handle undefined names
        const avatarUrl = avatars[item.receiver_pubkey]?.avatar || DefaultAvatar; // Fetch avatar or use default

        return (
            <div key={index} className={`SubscriptionRow ${rowClass}`}>
                <div className="Avatar">
    <a
        href={`https://primal.net/${item.name || 'Unknown'}`}
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            src={avatars[item.receiver_pubkey] || DefaultAvatar} // Use the mapped avatar or default
            alt="Avatar"
            className="avatar-img"
        />
    </a>
</div>

                <div className="RowItem NameRowItem">
                    <a
                        href={`https://primal.net/${userName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="NameLink"
                    >
                        {userName}
                    </a>
                </div>
                <div className="RowItem">{skuLabel}</div>
                <div className="RowItem">{item.revenue_usd != null ? `$${parseFloat(item.revenue_usd).toFixed(2)}` : 'N/A'}</div>
                <div className="RowItem">{item.revenue_type ?? 'N/A'}</div>
                <div className="RowItem">{item.origin ?? 'N/A'}</div>
                <div className="RowItem DateRowItem">{formatDate(item.purchased_at)}</div>
            </div>
        );
    })}
</div>
            </div>
        </>
    );
};

export default PremiumSubs;
